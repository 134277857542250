<template>
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchUsers"
      ref="SSearch"
      v-if="SSearchShow"
    ></s-search>
    <div class="table-button">
      <div>
        <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
          >刷新</a-button
        >
        <columns-set
          tableName="TakeDelivery"
          :columns="columns"
          @changeColumns="changeColumns"
        ></columns-set>
      </div>
    </div>
    <s-table
      ref="table"
      size="small"
      :rowKey="
        (record, index) => {
          return record.id;
        }
      "
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: '1400px', y: 'calc(82vh - 150px )' }"
      tableLayout="fixed"
    >
      <!--   :rowSelection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }" -->
      <!--  @change="changeTable" -->
      <!-- <span slot="wsBcId" slot-scope="text">
        <div>{{ CompaniesResult(text) }}</div>
      </span> -->

      <!-- 发货批次号 -->
      <span slot="logisticsNo" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            {{ text }}
          </template>
          {{ text }}
        </a-tooltip>
      </span>
      <!-- 订单号 -->
      <span slot="orderNo" slot-scope="text, record">
        <a-tooltip>
          <template slot="title">
            {{ text }}
          </template>
          <span class="logisticsProgress2" @click="GoToOrderDetails(record)">{{
            text
          }}</span>
        </a-tooltip>
      </span>
      <!-- 供应商 -->
      <span slot="supplierName" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            {{ text }}
          </template>
          {{ text }}
        </a-tooltip>
      </span>
      <!-- 收货地址 -->
      <span slot="receivingAddress" slot-scope="text">
        <a-tooltip>
          <template slot="title">
            {{ text }}
          </template>
          {{ text }}
        </a-tooltip>
      </span>
      <!-- 发货进度 -->
      <span slot="logisticsProgress" slot-scope="text">
        <div :class="'logisticsProgress' + text">
          {{ text | LogisticsProgress }}
        </div>
      </span>
      <!-- 操作 -->
      <span slot="action" slot-scope="text, record" class="action">
        <!--  v-if="
            isShowBtns.indexOf(
              'TakeDelivery-/api/constructionMaterial/addOrder'
            ) > -1
          " -->
        <a class="editBtn" @click="GoToReceiptDetails(record, '订单详情')"
          >详情</a
        >
      </span>
    </s-table>
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { SelectOptions } from "@/config/SelectOptions.js";
import { getWorkSitelist } from "@/api/apiJF/schedule";
import { LogisticsList } from "@/api/apiJF/material";
import { GetCompanies } from "@/api/device";
import moment from "moment";
// 搜索条件
const queryItems = [
  {
    label: "分公司",
    value: "cmBcIds",
    type: "select",
    selectOption: "CompanyListUnAll",
    defaultValue: "",
  },
  {
    label: "订单号",
    value: "orderNo",
    type: "input",
    defaultValue: "",
  },
  {
    label: "收货地址",
    value: "receivingAddress",
    type: "input",
    defaultValue: "",
  },
  {
    label: "收货人",
    value: "receivingName",
    type: "input",
    defaultValue: "",
  },
  {
    label: "发货日期",
    value: "senderTime",
    type: "dateRange",
    range: ["startTime", "endTime"],
    // keyType: "WorkerAttend",
    defaultValue: ["", ""],
  },
  {
    label: "供应商",
    value: "supplierName",
    type: "input",
    defaultValue: "",
  },
  {
    label: "发货进度",
    value: "logisticsProgress",
    type: "select",
    selectOption: "LogisticsProgress",
    defaultValue: "",
  },
];
const columns = [
  {
    title: "发货批次号",
    width: "200px",
    dataIndex: "logisticsNo",
    scopedSlots: { customRender: "logisticsNo" },
    ellipsis: true,
    align: "center",
  },
  {
    title: "订单号",
    width: "150px",
    dataIndex: "orderNo",
    scopedSlots: { customRender: "orderNo" },
    ellipsis: true,
    align: "center",
  },
  {
    title: "主要货物",
    width: "150px",
    dataIndex: "mainGoods",
    // align: "center",
    ellipsis: true,
  },
  {
    title: "发货日期",
    width: "120px",
    dataIndex: "senderTime",
    scopedSlots: { customRender: "senderTime" },
    align: "center",
  },
  {
    title: "到达日期",
    width: "90px",
    dataIndex: "arrivalTime",
    align: "center",
    scopedSlots: { customRender: "arrivalTime" },
  },
  {
    title: "收货人",
    width: "90px",
    dataIndex: "receivingName",
    align: "center",
  },
  {
    title: "收货地址",
    // // width: "100px",
    dataIndex: "receivingAddress",
    // // align: "center",
    ellipsis: true,
    scopedSlots: { customRender: "receivingAddress" },
  },

  {
    title: "供应商",
    width: "200px",
    dataIndex: "supplierName",
    // // align: "center",
    scopedSlots: { customRender: "supplierName" },
    ellipsis: true,
  },
  {
    title: "发货进度",
    width: "100px",
    dataIndex: "logisticsProgress",
    align: "center",
    scopedSlots: { customRender: "logisticsProgress" },
    ellipsis: true,
  },
  {
    title: "分公司",
    width: "100px",
    dataIndex: "companyName",
    align: "center",
  },
  {
    title: "操作",
    width: "90px",
    scopedSlots: { customRender: "action" },
    fixed: "right",
    dataIndex: "action",
    align: "center",
  },
];

export default {
  name: "TakeDelivery",
  components: {
    STable,
    SSearch,
    ColumnsSet,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      SSearchShow: true,
      DrawerTitle: "",
      // 查询参数
      queryParam: {
        cmBcIds: "",
        orderNo: "",
        receivingAddress: "",
        receivingName: "",
        startTime: "",
        endTime: "",
        supplierName: "",
        logisticsProgress: "",
        type: 1,
        pageNo: 1,
        pageSize: 10,
      },
      isShowBtns: [],
      record: "",
      CompanyList: {},
      type: "",
      filters: {},
      CompanyListid: [],
    };
  },
  created() {
    this.GetCompaniesId();
    this.tableColumns = columns;
    this.changeColumns();
    this.filters = SelectOptions;
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtnsJF").split(",");
  },
  methods: {
    // 获取分公司id
    GetCompaniesId() {
      GetCompanies()
        .then((res) => {
          // console.log(res);
          res.data.forEach((el) => {
            this.CompanyListid.push(el.bc_id);
            this.CompanyList[el.bc_id] = el.bc_name;
          });
        })
        .then(() => {
          if (this.queryParam.cmBcIds == "") {
            this.queryParam.cmBcIds = this.CompanyListid.toString();
            this.$refs.table.refresh(true);
          }

          //当包含的分公司小于1个的时候，查询条件不显示分公司查询 表格不显示分公司名称
          if (this.CompanyListid.length < 1) {
            let data = [...this.queryItems];
            this.queryItems = data.filter((item) => item.label != "公司名称");
            if (this.queryItems.length == 0) {
              this.SSearchShow = false;
            }
            let data1 = [...this.columns];
            this.tableColumns = data1.filter(
              (item) => item.title != "公司名称"
            );
            this.columns = data1.filter((item) => item.title != "公司名称");
          }
        });
    },
    CompaniesResult(a) {
      return this.CompanyList[a];
    },
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(
        localStorage.getItem("TakeDeliveryColumnsSet")
      );
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      if (parameter) {
        this.queryParam.pageNo = parameter.pageNo;
        this.queryParam.pageSize = parameter.pageSize;
      } else {
      }
      if (this.queryParam.cmBcIds != "") {
        let newObject = {};
        for (let el in this.queryParam) {
          if (this.queryParam[el] != "" && this.queryParam[el]) {
            newObject[el] = this.queryParam[el];
          }
        }
        let orderParam = Object.keys(newObject);
        const param = Object.assign({}, parameter, this.queryParam);
        const requestParameters = this.$order(param, orderParam);
        const params = {
          ...requestParameters,
          verify: this.$verify(requestParameters, orderParam),
        };
        return LogisticsList(params).then((res) => {
          // res.data.pageNo = res.data.page;
          // res.data.totalCount = res.data.sum;
          // console.log("收货情况", res);
          return res.data;
        });
      }
    },

    fetchUsers(value) {
      // this.queryParam = value;
      for (let el in this.queryParam) {
        this.queryParam[el] = value[el];
      }

      if (
        this.queryParam.cmBcIds == "" ||
        this.queryParam.cmBcIds == undefined
      ) {
        this.queryParam.cmBcIds = this.CompanyListid.toString();
      }
      this.queryParam.type = "1";
      this.$refs.table.refresh(true);
    },

    // 详情
    GoToReceiptDetails(record, name) {
      // console.log(name, record);
      //   console.log("tConstructionMaterials", tConstructionMaterials);
      let query = JSON.stringify(record);
      this.$router.push({
        path: "/ReceiptDetails",
        query: { query },
      });
    },
    // 跳往已下单-订单详情
    GoToOrderDetails(record) {
      console.log("record", record);
      let query = record;
      query.orderDetailsList = record.logisticsDetailsList;
      this.$router.push({
        path: "/OrderDetails",
        query: { record: JSON.stringify(record) },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.logisticsProgress1 {
  color: #20b01d;
}
.logisticsProgress2 {
  color: #1890ff;
}
.logisticsProgress3 {
  color: #999999;
}
</style>